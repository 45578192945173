﻿/* Lines for information output */

.block-information-row {
    display: flex;
    white-space: nowrap;
}

.block-information-col-titles {
    width: 80px;
}
