.documents {
    &-section {
        padding: 6px 0 32px;

        .component-file-upload-list {
            .data-list .data-list-container {
                min-width: auto;
            }

            .data-list-cell-doc-btn {
                flex: 0 0 53px;
                display: flex;
                justify-content: flex-end;

                .icon-delete {
                    margin-left: 16px;
                }
            }

            .data-list-cell-doc-name {
                flex: 1 1 70%;

                .icon {
                    margin-right: 8px;
                }
            }

            .data-list-cell-doc-date {
                flex: 0 0 150px;
            }

            .data-list-header {
                padding: 0 24px;
                color: $grey-8;
                font: 12px/32px $font-roboto-regular;
                border: 0;
                flex-basis: 32px;
                min-height: 32px;
                max-height: 32px;

                .data-list-cell:first-child {
                    padding-left: 0;
                }
            }

            .data-list-content:after {
                display: none;
            }

            .data-list-row {
                padding: 0 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                .progress-bar-content {
                    height: 100%;
                }
            }
        }

        .drag-zone {
            height: 80px;
            width: 100%;
            background-color: $primary-6;
            border: 1px dashed $primary-3;
            border-radius: $app-brd-radius;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 16px;

            .icon-svg {
                margin-right: 16px;
            }

            .btn-link {
                font: 14px $font-roboto-regular;
                margin-left: 4px;
            }

            p {
                margin: 0;
            }
        }
    }

    &-table {
        .data-list-row {
            height: auto;
            min-height: 32px;
            padding-right: 0;
        }

        .data-list-content {
            .data-list-cell {
                align-self: normal;
                min-width: 0;
                padding-left: 0;
                white-space: normal;

                &:not(:nth-child(-n + 2)) {
                    border-left: 1px solid $border-color;
                }
            }
        }

        .cell-upload-all {
            .tooltip-wrapper {
                display: flex;
                flex-direction: column;
                min-width: 0;
                margin-right: 8px;
                line-height: 16px;
            }
        }

        .cell-item-file {
            width: 100%;
            height: 32px;
            min-width: 0;
            
            &:not(:first-child):not(.btn-link) {
                border-top: 1px solid $border-color;
            }

            .icon {
                flex: none;
            }

            .file-description {
                padding-right: 41px;
            }

            .btn-link {
                top: 8px;
            }
        }
    }

    &-popup {
        .modal-content {
            width: 800px;
        }

        .modal-header {
            .documents-popup-download-documents {
                margin-left: auto;
                margin-right: 26px;
            }
        }

        .modal-body {
            height: 488px;
            max-height: 68vh;
            display: flex;
            flex-direction: column;
        }

        .tabs {
            .tooltip-wrapper {
                display: block;
            }
        }

        .documents-section {
            display: flex;
            flex-grow: 1;
            min-height: 1px;
            padding-top: 16px;
            padding-bottom: 0;

            .flex-row {
                padding-right: 16px;
            }

            .data-list-container {
                overflow: auto;
                height: 100%;
                display: block;
            }

            .data-list-header {
                position: sticky;
                top: 0;
                background: #fff;
                z-index: 2;
            }
        }

        .component-file-upload-list {
            display: flex;
            flex-grow: 1;
            min-width: 0;

            .data-list-cell-doc-btn {
                justify-content: flex-end;
                flex-basis: 56px;
                padding-left: 0;
            }
        }

        .empty-placeholder {
            margin: 16px 0 0;
        }
    }
}