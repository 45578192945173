﻿/* Popovers */
.kp-popover {
    width: max-content;
    z-index: 1070;

    /*sizes*/
    &.popover-md {
        .popover {
            width: 360px;
        }
    }
}

@media all and (-ms-high-contrast: none) {
    .kp-popover {
        width: 460px;

        &.popover-md {
            width: 360px;
        }
    }
}

.popover {
    padding: 14px 24px 24px;
    width: 460px;
    border-radius: $app-brd-radius;
    box-shadow: $popover-shadow;
    background-color: $popover-bg-color;

    .title-border {
        padding-bottom: 8px;
        margin-bottom: 16px;
        border-bottom: 1px solid $border-color;
    }

    .popover-text-box {
        margin-bottom: 32px;
    }

    .contacts-cards {
        li {
            border: 0;
            padding: 0;
            font: 14px/20px $font-roboto-regular;

            &:last-child {
                span:last-child {
                    margin-bottom: 0;
                }
            }

            span {
                margin-bottom: 8px;
            }
        }
    }

    .billing-info-details {
        padding-top: 24px;

        .definition-list {
            dt {
                margin-bottom: 4px;
            }

            dd {
                margin: 0;
            }
        }
    }
}

.popover-header {
    position: relative;

    h1 {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        margin-right: 36px;
        white-space: nowrap;
    }

    .btn-close {
        position: absolute;
        top: -2px;
        right: -8px;
    }
}

.popover-content {
    .js-plotly-plot,
    .graph-box {
        width: 100%;
    }

    .graph-details {
        margin-top: -7px;
        white-space: normal;
    }
}


.popover-grid-help {
    font-size: 14px;
    width: 412px;
    padding: 14px 20px 12px;
    text-transform: none;
    box-shadow: $app-shadow;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    z-index: 2;

    .popover-header {
        .btn-close {
            top: -7px;
            right: -11px;
        }

        p {
            margin: 0;

            .icon-svg {
                color: $blue-5;
                margin-right: 12px;
            }
        }
    }

    .popover-body {
        padding: 9px 0 0;

        ol {
            margin-left: 30px;
        }
    }
}

.popover-onboarding {
    position: absolute;
    width: 244px;
    margin-top: 10px;
    color: $white-1;
    font-size: 12px;
    border: 0;
    background-color: $grey-4;
    padding: 0;
    border-radius: $app-brd-radius;
    z-index: 20;

    &:before {
        content: ' ';
        position: absolute;
        top: -6px;
        left: 23px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 6px 5px;
        border-color: transparent transparent $grey-4;
    }

    &-right {
        left: auto;
        right: 0;

        &:before {
            left: auto;
            right: 23px;
        }
    }

    .popover-header {
        button {
            position: absolute;
            top: 0;
            right: 0;
            padding: 6px;
            border: 0;
            color: inherit;
            background: none;
            line-height: 8px;
            cursor: pointer;

            .icon {
                vertical-align: top;
            }
        }
    }

    .popover-body {
        padding: 0;
    }

    .popover-content {
        flex-direction: column;
        padding: 22px 16px 16px;
        text-align: left;
        line-height: 1.38;
        word-break: break-word;

        p {
            width: 100%;
        }

        .flex-row {
            width: 100%;
            padding: 0;
            font-size: 12px;
        }

        .btn-link {
            color: $white-1;

            & + .btn-main {
                margin-left: 10px;
            }
        }
    }
}

.disabled-action-button {
    pointer-events: none;
}

.add-portfolio-popover {
    .options-list ul {
        position: static;
    }
}

/* end Popovers */

.eval-box {
    display: flex;
    align-items: center;
    height: 23px;
    background-color: #f7ebd0;
    padding: 0 8px 0 4px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &-text {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
    }

    .icon-svg-kopentech {
        margin-right: 4px;
    }

    .icon-svg-info {
        margin-left: 4px;
    }

    &.animated {
        &:before {
            animation: leftToRightMove 3s infinite;
        }
    }

    &:before {
        position: absolute;
        content: '';
        width: 15px;
        height: 100%;
        left: -15px;
        top: 0;
        background-color: #FFB400;
        opacity: .15;
    }
}

.eval-box-popover.kp-tooltip {
    max-width: 262px;
    line-height: 1.15;

    .new-feature-head {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-family: $font-roboto-medium;
    
        .icon-svg {
            margin-right: 4px;
        }
    }
}

@keyframes leftToRightMove {
    0% {
        left: 0;
    }

    25% {
        left: 100%;
    }

    100% {
        left: 100%;
    }
}
